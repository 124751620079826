<template>
    <div>
        <div class="flex flex-col flex-1 h-full px-4">
            <table class="min-w-full quext-table">
                <thead>
                    <tr>
                        <th class="pl-2">floor plan</th>
                        <th>living</th>
                        <th>leasing</th>
                        <th>unit type</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="floorplan in floorplans" :key="floorplan.community_floorplans_name" class="cursor-default">
                        <td class="flex py-4 pl-4 whitespace-no-wrap items-center justify-start">
                            <img v-if="floorplan.gallery.images.length" :src="floorplan.gallery.images[0].thumbnail" class="h-10 w-10 " />
                            <div v-else class="h-10 w-10 bg-gray-100 flex items-center justify-center">
                                <Icon name="photo" class="h-5 w-5 text-gray-300" />
                            </div>
                            <div class="ml-4">
                                <div class="text-sm leading-5 font-medium text-gray-900">
                                    {{ floorplan.name}}
                                </div>
                                <div class="text-sm leading-5 text-gray-500">
                                    {{ floorplan.common_name}}
                                </div>
                            </div>
                        </td>
                        <td class="whitespace-no-wrap ">
                            <div class="text-sm">
                                <span class="text-xs text-gray-600">Sq Ft:</span> {{ floorplan.square_feet }}
                            </div>
                            <div class="text-sm">
                                <span class="text-xs text-gray-600">Beds:</span> {{ floorplan.bedrooms }} 
                                <span class="text-xs text-gray-300 font-medium pr-1">/</span> 
                                <span class="text-xs text-gray-600">Baths:</span> {{ floorplan.bathrooms }}
                            </div>
                        </td>
                        <td class="whitespace-no-wrap ">
                            <div class="text-sm">
                                <span class="text-xs text-gray-600">Units:</span> {{ floorplan.available_units }}
                            </div>
                            <div class="text-sm">
                                <span class="text-xs text-gray-600">Starting:</span> ${{ floorplan.starting_price }}
                            </div>
                        </td>
                        <td class="whitespace-no-wrap">
                            <div class="text-sm">{{ floorplan.unit_type }}</div>
                        </td>
                        <td class="text-right pr-4">
                            <modal-link
                                route="websites.floorplans.show"
                                :params="{ floorplanName: floorplan.name}"
                            >
                                <div class="text-link">view</div>
                            </modal-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import Icon from "@/components/ui/Icon";
    import {mapActions, mapGetters} from "vuex";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalLink from "@/components/ui/modals/ModalLink";

    export default {
        mixins: [ ModalNavigation ],
        components: {Icon, ModalLink},
        data: () => {
            return {
                website: {},
            }
        },
        computed: {
            ...mapGetters({
                floorplans: 'floorplans/getFloorplans',
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
        },
        methods: {
            ...mapActions({
                loadFloorplansInStore: 'floorplans/loadFloorplans',
                removeActionFromStore: 'actions/removeAction',
                resetActions: 'actions/resetActions',
            })          
        },
        created() {
            this.resetActions();
        },
        async mounted() {
            this.website = this.getWebsiteInfo
            this.$cmsDataProvider.get('floorplans', {websiteId: this.website.id}).then((response) => {
                
                if(response[0].source != null){
                  this.removeActionFromStore({title: 'Add floor plan', routeName: 'websites.floorplans'})
                  this.removeActionFromStore({title: 'Import Availability', routeName: 'websites.floorplans'})
                }else{
                   this.resetActions();
                }
                this.loadFloorplansInStore(response);
            })
        },
    }
</script>

<style scoped>
.quext-table {
    @apply font-frank;
}

.quext-table th {
    @apply font-bold text-sm tracking-wider text-left border-b-2 border-gray-500 pb-1;
}
</style>
